export const TermBereich = `
    bereich {
        ... on Term_Bereich_Bereich {
            id
            slug
            title
        }
    }
`

export const TermSchadensart = `
    schadensart {
        ... on Term_Schadensart_Schadensart {
            id
            slug
            title
        }
    }
`

export const TermServiceart = `
    serviceart {
        ... on Term_Serviceart_Serviceart {
            id
            slug
            title
        }
    }
`
