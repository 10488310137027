//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      showNav: true,
      pageYOffset: 0,
    }
  },

  computed: {
    mainNav() {
      const mainNav = this.$store.getters['navigations/mainNav']
      if (!mainNav) return []
      return mainNav
    },

    metaNav() {
      const metaNav = this.$store.getters['navigations/metaNav']
      if (!metaNav) return []
      return metaNav
    },

    mobileMetaNav() {
      const mobileMetaNav = this.$store.getters['navigations/mobileMetaNav']
      if (!mobileMetaNav) return []
      return mobileMetaNav
    },

    langs() {
      const langs = this.$store.getters['navigations/languages']
      if (!langs) return []
      return langs
    },
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    handleScroll(event) {
      const prevScrollpos = this.pageYOffset

      const currentScrollPos = window.pageYOffset
      if (prevScrollpos > currentScrollPos || currentScrollPos < 90) {
        this.showNav = true
      } else {
        this.showNav = false
      }
      this.pageYOffset = currentScrollPos
    },
  },
}
