import { localize } from 'vee-validate'

export default ({ app, store }) => {
  app.i18n.onLanguageSwitched = (oldLocale, newLocale) => {
    localize(newLocale === 'dk' ? 'da' : newLocale)

    Promise.all([
      store.dispatch('navigations/getNavigations'),
      store.dispatch('getNewsletterMessages'),
      store.getters['cookie-banner/show'] !== false
        ? store.dispatch('cookie-banner/getContent')
        : null,
    ])
  }
}
