import { gql } from 'nuxt-graphql-request'

export const Asset = gql`
  fragment Asset on AssetInterface {
    url
    ... on Asset_Assets {
      id
      alt
      alt_fr
      alt_en
      alt_dk
      permalink
      url
      extension
      is_image
      width
      height
      focus_css
    }
  }
`
