/* eslint-disable prettier/prettier */
import { getNavigations } from '~/graphql/queries'

export const state = () => ({
    isOpen: false,
    mainNav: {},
    footerNav: {},
    metaNav: {},
    mobileMetaNav: {},
    languages: {},
})

export const actions = {
    async getNavigations({ commit }) {
        const lang = this.app.i18n.locale === 'de' ? 'de_CH' : this.app.i18n.locale
        try {
            const navigations = await this.$graphql.cms.request(getNavigations, {
                lang,
            })

            commit('setNavigations', navigations)
        } catch (err) {
            console.error(JSON.stringify(err, undefined, 2))
        }
    },
}

export const mutations = {
    setNavigations(state, value) {
        state.mainNav = value.navs.filter((nav) => nav.handle === 'mainnav')[0]
        state.footerNav = value.navs.filter((nav) => nav.handle === 'footer')[0]
        state.metaNav = value.navs.filter((nav) => nav.handle === 'meta_navigation')[0]
        state.mobileMetaNav = value.navs.filter((nav) => nav.handle === 'mobile_meta_navigation')[0]
        state.languages = value.sites
    },
    openNav(state) {
        state.isOpen = true
    },
    closeNav(state) {
        state.isOpen = false
    },
}

export const getters = {
    isOpen: (state) => {
        return state.isOpen
    },
    mainNav: (state) => {
        if (!state.mainNav) return {}
        return state.mainNav.tree
    },
    metaNav: (state) => {
        if (!state.metaNav) return {}
        return state.metaNav.tree
    },
    mobileMetaNav: (state) => {
        if (!state.mobileMetaNav) return {}
        return state.mobileMetaNav.tree
    },
    languages: (state) => {
        if (!state.languages) return {}
        return state.languages
    },

    footerNav: (state) => {
        if (!state.footerNav) return {}
        return state.footerNav.tree
    },
}
