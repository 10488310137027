//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    mainnav: {
      type: Array,
      default: () => [],
    },

    metanav: {
      type: Array,
      default: () => [],
    },

    langs: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      openLangSwitcher: false,
      translateX: 0,
      width: 0,
      hoverIndex: 0,
      lineOnHover: false,
    }
  },

  computed: {
    langSwitch() {
      // Order Langs
      return [
        ...this.langs.filter(
          (l) =>
            l.short_locale === this.$i18n.locale &&
            (l.short_locale === 'de' ||
              l.short_locale === 'fr' ||
              l.short_locale === 'en')
        ),
        ...this.langs.filter(
          (l) =>
            l.short_locale !== this.$i18n.locale &&
            (l.short_locale === 'de' ||
              l.short_locale === 'fr' ||
              l.short_locale === 'en')
        ),
      ]
    },
  },

  watch: {
    $route(to, from) {
      this.$nextTick(function () {
        this.openLangSwitcher = false
        this.calcCurrentNavpointIndex(to)
      })
    },

    mainnav() {
      this.$nextTick(function () {
        this.openLangSwitcher = false
        this.calcCurrentNavpointIndex()
      })
    },

    hoverIndex() {
      this.setUnderline()
    },
  },

  mounted() {
    this.calcCurrentNavpointIndex()
    this.setUnderline()
    window.addEventListener('resize', this.setUnderline)
  },

  destroyed() {
    window.removeEventListener('resize', this.setUnderline)
  },

  methods: {
    calcCurrentNavpointIndex(routeTo) {
      let found = false
      this.mainnav.forEach((point, index) => {
        if (
          point.page.url.replace(/\//g, '') ===
            this.$route.path.replace(/\//g, '') ||
          point.page.url.replace(/\//g, '') === routeTo?.path.replace(/\//g, '')
        ) {
          this.hoverIndex = index
          found = true
        }
      })
      if (!found) {
        this.hoverIndex = null
      }
    },

    setUnderline() {
      if (this.hoverIndex === null) {
        this.width = 0
      } else {
        this.$nextTick(function () {
          if (!this.$refs.navbaritem) return

          const navEl = this.$refs.navbaritem[this.hoverIndex]
          const widthsmaler =
            parseFloat(getComputedStyle(navEl.firstChild).paddingLeft) +
            parseFloat(getComputedStyle(navEl.firstChild).paddingRight)

          this.width = navEl.clientWidth - widthsmaler

          this.translateX = 0
          for (let i = 0; i < this.hoverIndex; i++) {
            this.translateX += this.$refs.navbaritem[i].clientWidth
          }
          this.translateX = this.translateX + widthsmaler / 2
        })
      }
    },

    hoverOverNavpoint(index) {
      if (index === this.hoverIndex) {
        this.lineOnHover = true
      }
    },

    hoverNotOverNavpoint() {
      this.lineOnHover = false
    },
  },
}
