//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    mainnav: {
      type: Array,
      default: () => [],
    },

    metanav: {
      type: Array,
      default: () => [],
    },

    mobilemetanav: {
      type: Array,
      default: () => [],
    },

    langs: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      openLangSwitcher: false,
      navOpen: false,
    }
  },

  computed: {
    langSwitch() {
      // Order Langs
      return [
        ...this.langs.filter(
          (l) =>
            l.short_locale === this.$i18n.locale &&
            (l.short_locale === 'de' ||
              l.short_locale === 'fr' ||
              l.short_locale === 'en')
        ),
        ...this.langs.filter(
          (l) =>
            l.short_locale !== this.$i18n.locale &&
            (l.short_locale === 'de' ||
              l.short_locale === 'fr' ||
              l.short_locale === 'en')
        ),
      ]
    },

    nav() {
      return [...this.mainnav, ...this.metanav]
    },
  },

  watch: {
    $route(to, from) {
      this.openLangSwitcher = false
      this.navOpen = false
    },
  },
}
