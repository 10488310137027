import { render, staticRenderFns } from "./NavMobile.vue?vue&type=template&id=5ac23186&scoped=true&"
import script from "./NavMobile.vue?vue&type=script&lang=js&"
export * from "./NavMobile.vue?vue&type=script&lang=js&"
import style0 from "./NavMobile.vue?vue&type=style&index=0&id=5ac23186&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ac23186",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LogoIcon: require('/vercel/path0/components/icons/LogoIcon.vue').default,AppLink: require('/vercel/path0/components/general/AppLink.vue').default})
