import { getCookeySettings } from '~/graphql/queries'

export const state = () => ({
  show: true,
  content: null,
})

export const actions = {
  async getContent({ commit }) {
    const lang = this.app.i18n.locale === 'de' ? 'de_CH' : this.app.i18n.locale
    try {
      const data = await this.$graphql.cms.request(getCookeySettings, {
        lang,
      })

      commit('setContent', data.globalSet)
    } catch (err) {
      console.error(JSON.stringify(err, undefined, 2))
    }
  },
}

export const mutations = {
  setContent(state, value) {
    state.content = value
  },

  setShow(state, value) {
    state.show = value
  },
}

export const getters = {
  show: (state) => state.show,

  content: (state) => state.content,
}
