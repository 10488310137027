/* eslint-disable prettier/prettier */

import { gql } from 'nuxt-graphql-request'
import {
    page,
    ContentBlock,
    Footer,
    HomeHero,
    ContactHero,
    SubNav,
    ConsultingBtn,
    Team,
    NormalHero
} from './fragments-component'
import {
    TermBereich,
    TermSchadensart,
    TermServiceart
} from './terms'


// BLUEPRINT PAGES
export const Pages = gql`
fragment Pages on Entry_Pages_Pages {
    ${page}
    appearance {
        value
        label
    }
    seo_title
    seo_description
    seo_image {
        ...Asset
    }
    seo_hidden
    ${ConsultingBtn}

    components: components {
        ... on Set_Components_ContentBlock {
            ${ContentBlock}
        }
        ... on Set_Components_ComponentContactHero {
            ${ContactHero}
        }
        ... on Set_Components_ComponentHomeHero {
             ${HomeHero}
        }
        ... on Set_Components_ComponentNormalHero {
            ${NormalHero}
        }
        ... on Set_Components_ComponentSubNavigation {
            ${SubNav}
        }
        ... on Set_Components_Team {
            ${Team}
        }
      
    }
    footer {
        ${Footer}
    }
  }
`
// BLUEPRINT UnsereServices
export const UnsereServices = gql`
fragment UnsereServices on Entry_Pages_UnsereServices {
    ${page}
    seo_title
    seo_description
    seo_image {
        ...Asset
    }
    seo_hidden
    ${ConsultingBtn}

    components: components {
        ... on Set_Components_ContentBlock {
            ${ContentBlock}
        }
        ... on Set_Components_ComponentContactHero {
            ${ContactHero}
        }
        ... on Set_Components_ComponentHomeHero {
             ${HomeHero}
        }
        ... on Set_Components_ComponentNormalHero {
            ${NormalHero}
        }
        ... on Set_Components_ComponentSubNavigation {
            ${SubNav}
        }
        ... on Set_Components_Team {
            ${Team}
        }
    }

   footer {
       ${Footer}
   }
  }
`

// BLUEPRINT Services Blueprint
export const Service = gql`
fragment Service on Entry_Services_Services {
    ${page}
    seo_title
    seo_description
    seo_image {
        ...Asset
    }
    seo_hidden
    ${ConsultingBtn}

    content_block {
        ... on Set_ContentBlock_ContentBlock {
                ${ContentBlock}
            }
    }

    header {
        __typename
        h1_tag
        show_breadcrumbs
        description
        content_text {
          ... on Set_ContentText_Tag {
            text
            link
            type
          }
          ... on Set_ContentText_OpenEmailTemplateButton {
            button_title
            email
            product_or_education
            type
          }
          ... on BardText {
            text
            type
          }
        }
        header_title
        hasImage
        image_type {
          value
          label
        }
        big_image {
            ...Asset
        }
        smal_images {
            ...Asset
        }
    }
    footer {
        ${Footer}
    }

  }
`

// ServicesCart
export const ServicesCart = `
    page_title
    date
    card_text
    new_til_date
    header {
        big_image {
            ...Asset
        }
        smal_images {
            ...Asset
        }
    }
    url
    slug
   ${TermBereich}
   ${TermSchadensart}
   ${TermServiceart}
`
