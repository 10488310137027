export const removePTags = (html) => {
  if (!html) return null
  return html.replace(/<\/p[^>]*><p[^>]*>/g, '<br>').replace(/<\/?p[^>]*>/g, '')
}

// export const getPageType = (blueprint) => {
//   switch (blueprint) {
//     case 'home':
//       return 'Home'
//     case 'pages':
//       return 'Seite'
//     case 'blogs_and_news':
//       return 'Blog & News'
//     case 'jobs':
//       return 'Job'
//     case 'references':
//       return 'Referenz'
//     case 'competencies':
//       return 'Kompetenz'
//     case 'contact':
//       return 'Kontakt'
//     default:
//       return ''
//   }
// }
