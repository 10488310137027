export const ComponentLoader = () => import('../../components/loaders/ComponentLoader.vue' /* webpackChunkName: "components/component-loader" */).then(c => wrapFunctional(c.default || c))
export const ContentBlockLoader = () => import('../../components/loaders/ContentBlockLoader.vue' /* webpackChunkName: "components/content-block-loader" */).then(c => wrapFunctional(c.default || c))
export const PageLoader = () => import('../../components/loaders/PageLoader.vue' /* webpackChunkName: "components/page-loader" */).then(c => wrapFunctional(c.default || c))
export const AppImage = () => import('../../components/general/AppImage.vue' /* webpackChunkName: "components/app-image" */).then(c => wrapFunctional(c.default || c))
export const AppLink = () => import('../../components/general/AppLink.vue' /* webpackChunkName: "components/app-link" */).then(c => wrapFunctional(c.default || c))
export const BigTag = () => import('../../components/general/BigTag.vue' /* webpackChunkName: "components/big-tag" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumbs = () => import('../../components/general/Breadcrumbs.vue' /* webpackChunkName: "components/breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const ConsultingBtn = () => import('../../components/general/ConsultingBtn.vue' /* webpackChunkName: "components/consulting-btn" */).then(c => wrapFunctional(c.default || c))
export const ContactHero = () => import('../../components/general/ContactHero.vue' /* webpackChunkName: "components/contact-hero" */).then(c => wrapFunctional(c.default || c))
export const Container = () => import('../../components/general/Container.vue' /* webpackChunkName: "components/container" */).then(c => wrapFunctional(c.default || c))
export const CookeyBanner = () => import('../../components/general/CookeyBanner.vue' /* webpackChunkName: "components/cookey-banner" */).then(c => wrapFunctional(c.default || c))
export const HomeHero = () => import('../../components/general/HomeHero.vue' /* webpackChunkName: "components/home-hero" */).then(c => wrapFunctional(c.default || c))
export const List = () => import('../../components/general/List.vue' /* webpackChunkName: "components/list" */).then(c => wrapFunctional(c.default || c))
export const NormalHero = () => import('../../components/general/NormalHero.vue' /* webpackChunkName: "components/normal-hero" */).then(c => wrapFunctional(c.default || c))
export const OpenEmailTemplateButton = () => import('../../components/general/OpenEmailTemplateButton.vue' /* webpackChunkName: "components/open-email-template-button" */).then(c => wrapFunctional(c.default || c))
export const Pdf = () => import('../../components/general/Pdf.vue' /* webpackChunkName: "components/pdf" */).then(c => wrapFunctional(c.default || c))
export const Section = () => import('../../components/general/Section.vue' /* webpackChunkName: "components/section" */).then(c => wrapFunctional(c.default || c))
export const SectionFull = () => import('../../components/general/SectionFull.vue' /* webpackChunkName: "components/section-full" */).then(c => wrapFunctional(c.default || c))
export const ServiceCard = () => import('../../components/general/ServiceCard.vue' /* webpackChunkName: "components/service-card" */).then(c => wrapFunctional(c.default || c))
export const SubNavigation = () => import('../../components/general/SubNavigation.vue' /* webpackChunkName: "components/sub-navigation" */).then(c => wrapFunctional(c.default || c))
export const Tag = () => import('../../components/general/Tag.vue' /* webpackChunkName: "components/tag" */).then(c => wrapFunctional(c.default || c))
export const Team = () => import('../../components/general/Team.vue' /* webpackChunkName: "components/team" */).then(c => wrapFunctional(c.default || c))
export const VideoPlayer = () => import('../../components/general/VideoPlayer.vue' /* webpackChunkName: "components/video-player" */).then(c => wrapFunctional(c.default || c))
export const Pages = () => import('../../components/blueprints/Pages.vue' /* webpackChunkName: "components/pages" */).then(c => wrapFunctional(c.default || c))
export const Services = () => import('../../components/blueprints/Services.vue' /* webpackChunkName: "components/services" */).then(c => wrapFunctional(c.default || c))
export const UnsereServices = () => import('../../components/blueprints/UnsereServices.vue' /* webpackChunkName: "components/unsere-services" */).then(c => wrapFunctional(c.default || c))
export const ContentBlockImages = () => import('../../components/contentBlock/ContentBlockImages.vue' /* webpackChunkName: "components/content-block-images" */).then(c => wrapFunctional(c.default || c))
export const NewsletterField = () => import('../../components/contentBlock/NewsletterField.vue' /* webpackChunkName: "components/newsletter-field" */).then(c => wrapFunctional(c.default || c))
export const SectionGraphicTable = () => import('../../components/contentBlock/SectionGraphicTable.vue' /* webpackChunkName: "components/section-graphic-table" */).then(c => wrapFunctional(c.default || c))
export const SectionWithTitle = () => import('../../components/contentBlock/SectionWithTitle.vue' /* webpackChunkName: "components/section-with-title" */).then(c => wrapFunctional(c.default || c))
export const SubrubricSection = () => import('../../components/contentBlock/SubrubricSection.vue' /* webpackChunkName: "components/subrubric-section" */).then(c => wrapFunctional(c.default || c))
export const ArrowIcon = () => import('../../components/icons/ArrowIcon.vue' /* webpackChunkName: "components/arrow-icon" */).then(c => wrapFunctional(c.default || c))
export const FilterArrowIcon = () => import('../../components/icons/FilterArrowIcon.vue' /* webpackChunkName: "components/filter-arrow-icon" */).then(c => wrapFunctional(c.default || c))
export const LogoIcon = () => import('../../components/icons/LogoIcon.vue' /* webpackChunkName: "components/logo-icon" */).then(c => wrapFunctional(c.default || c))
export const PlayIcon = () => import('../../components/icons/PlayIcon.vue' /* webpackChunkName: "components/play-icon" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/nav/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const FooterNav = () => import('../../components/nav/FooterNav.vue' /* webpackChunkName: "components/footer-nav" */).then(c => wrapFunctional(c.default || c))
export const MainNav = () => import('../../components/nav/MainNav.vue' /* webpackChunkName: "components/main-nav" */).then(c => wrapFunctional(c.default || c))
export const NavDesktop = () => import('../../components/nav/NavDesktop.vue' /* webpackChunkName: "components/nav-desktop" */).then(c => wrapFunctional(c.default || c))
export const NavMobile = () => import('../../components/nav/NavMobile.vue' /* webpackChunkName: "components/nav-mobile" */).then(c => wrapFunctional(c.default || c))
export const Newsletter = () => import('../../components/nav/Newsletter.vue' /* webpackChunkName: "components/newsletter" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
