//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    error: {
      type: Object,
      default: () => ({}),
    },
  },

  mounted() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  },
}
