import { render, staticRenderFns } from "./default.vue?vue&type=template&id=2b59c800&"
var script = {}


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MainNav: require('/vercel/path0/components/nav/MainNav.vue').default,CookeyBanner: require('/vercel/path0/components/general/CookeyBanner.vue').default})
