/* eslint-disable prettier/prettier */

import Vue from 'vue'
import {
    ValidationObserver,
    ValidationProvider,
    extend,
    localize,
} from 'vee-validate'

import { required, email } from 'vee-validate/dist/rules'
import de from 'vee-validate/dist/locale/de.json'
import en from 'vee-validate/dist/locale/en.json'
import fr from 'vee-validate/dist/locale/fr.json'
import da from 'vee-validate/dist/locale/da.json'

extend('required', {
    ...required,
})
extend('email', {
    ...email,
})

localize({
    de,
    en,
    fr,
    da,
})

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

export default function ({ $axios, app, error }) {
    localize(app.i18n.locale)
}
