//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { removePTags } from '~/utils'

export default {
  computed: {
    showbanner() {
      return this.$store.getters['cookie-banner/show']
    },

    text() {
      return this.$store.getters['cookie-banner/content']?.text
    },

    link() {
      return {
        link: this.$store.getters['cookie-banner/content']?.link,
        text: this.$store.getters['cookie-banner/content']?.button_text,
      }
    },
  },

  async mounted() {
    if (this.showbanner) {
      await this.$store.dispatch('cookie-banner/getContent')
    }
  },

  methods: {
    hideBanner() {
      this.$store.commit('cookie-banner/setShow', false)
    },

    removePTags(text) {
      return removePTags(text)
    },
  },
}
