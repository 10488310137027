import { gql } from 'nuxt-graphql-request'
import { Asset } from './fragments-asset'
import {
  Pages,
  UnsereServices,
  ServicesCart,
  Service,
} from './fragments-blueprint'

// GET PAGES BY URL
export const getPageByUri = gql`
  query getPageByUri($uri: String, $lang: String) {
    entry(uri: $uri, site: $lang) {
      url
      alternates {
        url
        language
        is_root
      }
      ...Pages
      ...UnsereServices
      ...Service
    }
  }
  ${Pages}
  ${UnsereServices}
  ${Service}
  ${Asset}
`

// GET Navigations
export const getNavigations = gql`
  query getNavigations($lang: String) {
    navs {
      title
      tree(site: $lang) {
        page {
          url
          title
          id
        }
        children {
          page {
            url
            title
            id
          }
          depth
        }
      }
      max_depth
      handle
    }
    sites {
      locale
      handle
      name
      short_locale
      url
    }
  }
`
// GET all services
export const getAllServices = gql`
  query getAllServices($lang: String) {
  __typename
    entries(collection: "services", filter: { site: $lang }, sort: "order") {
      data {
        url
        permalink
        ... on Entry_Services_Services {
          ${ServicesCart}
        }
      }
    }
    taxonomies {
      title
      handle
    }
    terms(sort: "ordernumber asc", filter: { site: $lang }) {
      from
      data {
        id
        title
        taxonomy {
          handle
          title
        }
      }
    }
  }
  ${Asset}
`

// GET Cookey settings
export const getCookeySettings = gql`
  query getCookeySettings($lang: String) {
    globalSet(handle: "cookey_settings", site: $lang) {
      ... on GlobalSet_CookeySettings {
        text
        button_text
        link
      }
    }
  }
`

// GET Email settings
export const getEmailSettings = gql`
  query getEmailSettings($lang: String) {
    globalSet(handle: "mail_preset", site: $lang) {
      ... on GlobalSet_MailPreset {
        text_education
        text_product
      }
    }
  }
`

// GET newsletter_messages
export const getNewsletterMessages = gql`
  query getNewsletterMessages($lang: String) {
    globalSet(handle: "newsletter_messages", site: $lang) {
      ... on GlobalSet_NewsletterMessages {
        success
        error
        loading
      }
    }
  }
`
