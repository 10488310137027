/* eslint-disable prettier/prettier */

import {
    BardText,
    ContendList,
    NewsletterField,
    ContendImage,
    ContendVideo,
    ContendPdf,
    Tag,
    GraphicTable
} from './contend-block-component'


export const SubrubricSectionContend = `
    ... on Set_Contend_Contend_Image {
        ${ContendImage}
    }
    ... on BardText {
        ${BardText}
    }
    ... on Set_Contend_Contend_List {
        ${ContendList}
    }
    ... on Set_Contend_Contend_Tag {
        ${Tag}
    }
    ... on Set_Contend_Contend_Pdf {
        ${ContendPdf}
      }
      ... on Set_Contend_Contend_Video {
        ${ContendVideo}
    }
`

export const ContentBlock = `
    rubric
    background_gray
    type
    content_block_components {
        ... on Set_ContentBlockComponents_ContentBlockComponentSectionWithTitle {
            type
            title
            
            contend {
                ... on Set_Contend_Image {
                    ${ContendImage}
                }
                ... on Set_Contend_Video {
                    ${ContendVideo}
                }
                ... on BardText {
                    ${BardText}
                }
                ... on Set_Contend_List {
                    ${ContendList}
                }
                ... on Set_Contend_Pdf {
                    ${ContendPdf}
                  }
                ... on Set_Contend_Tag {
                    ${Tag}
                }
                ... on Set_Contend_NewsletterField {
                    ${NewsletterField}
                }
                ... on Set_Contend_SubrubricSection {
                    subrubrik
                    type
                    contend {
                        ${SubrubricSectionContend}
                    }
                }
                }
            }
        ... on Set_ContentBlockComponents_ContentBlockComponentGraphicTable {
            ${GraphicTable}  
        }
    }
`



export const Footer = `
    showCTA
    call_to_action
    tags {
        ... on Set_Tags_Tag {
        text
        link
        type
        }
    }
`

export const HomeHero = `
    type
    pre_title
    title
    text
    images_verticalformat {
        ...Asset
    }
    images_landscapeformat {
        ...Asset
    }
`

export const NormalHero = `
    type
    description
    image_type {
        value
        label
    }
    header_title
    hasImage
    h1_tag
    show_breadcrumbs
    big_image {
        ...Asset
    }
    smal_images {
        ...Asset
    }   
    video {
        ...Asset
    }   
    content_text {
        ... on Set_ContentText_Tag {
            ${Tag}
        }
       
        ... on Set_ContentText_Pdf {
            link_text
            type
            pdf {
                permalink
              }
          }
        ... on BardText {
            type
            text
        }
    }
`

export const ContactHero = `
    adress
    content_text_contact {
        ... on Set_ContentTextContact_Tag {
            ${Tag}
        }
        ... on BardText {
            type
            text
        }
    }
    show_breadcrumbs
    title
    type
`

export const SubNav = `
    type
    navigation_points {
        text
        link
      }
    description
    text
`

export const Team = `
    type
    person {
    ... on Set_Person_Person {
        name
        job
        type        
            image {
                ...Asset
            }
            secondary_image {
                ...Asset
            }
        }
    }
`



export const image = `
    type
    image {
        ...Asset
    }
`

export const page = `
    slug
    title
    blueprint
  
`
export const ConsultingBtn = `
    show_consulting_btn
    consulting_btn_text
    consulting_btn_link    
`
