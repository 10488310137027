import { render, staticRenderFns } from "./NavDesktop.vue?vue&type=template&id=14a4732d&scoped=true&"
import script from "./NavDesktop.vue?vue&type=script&lang=js&"
export * from "./NavDesktop.vue?vue&type=script&lang=js&"
import style0 from "./NavDesktop.vue?vue&type=style&index=0&id=14a4732d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14a4732d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LogoIcon: require('/vercel/path0/components/icons/LogoIcon.vue').default,AppLink: require('/vercel/path0/components/general/AppLink.vue').default})
