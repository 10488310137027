import Vue from 'vue'
import VueGtag from 'vue-gtag'

export default ({ isDev, app }) => {
  if (!isDev) {
    Vue.use(VueGtag, {
      config: {
        id: 'G-DBW7FQEX4V',
        params: {
          send_page_view: true,
        },
      },
    })
  } else {
    console.log('Skipping GA in development')
  }
}
