import { getNewsletterMessages } from '~/graphql/queries'

/* eslint-disable prettier/prettier */
export const state = () => ({
    blueprint: '',
    heroType: '',
    newsletterMessages: '',
})

export const actions = {
    async nuxtServerInit({ dispatch }) {
        try {
            await Promise.all([
                dispatch('navigations/getNavigations'),
                dispatch('getNewsletterMessages'),
            ])

        } catch (err) {
            console.error(JSON.stringify(err, undefined, 2))
        }
    },

    async getNewsletterMessages({ commit }) {
        try {
            const lang = this.$i18n.locale === 'de' ? 'de_CH' : this.$i18n.locale
            const newsletterMessages = await this.$graphql.cms.request(getNewsletterMessages, { lang })

            commit('setNewsletterMessages', newsletterMessages)
        } catch (err) {
            console.error(JSON.stringify(err, undefined, 2))
        }
    },
}

export const mutations = {
    setBlueprint(state, value) {
        state.blueprint = value
    },
    setHeroType(state, value) {
        state.heroType = value
    },
    setNewsletterMessages(state, value) {
        state.newsletterMessages = value
    }
}

export const getters = {
    blueprint: (state) => {
        return state.blueprint
    },
    heroType: (state) => {
        return state.heroType
    },
    newsletterMessages: (state) => {
        return state.newsletterMessages
    },
}
