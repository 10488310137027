// import { Asset } from './fragments-asset'

export const BardText = `
    text
    type
`

export const ContendList = `
    type
    number_of_columns {
    value
    label
    }
    list {
    text_field
    link_field
    }
`

export const NewsletterField = `
    type
`

export const ContendImage = `
    type
    aspectratio {
        value
        label
    }
    images {
        ... Asset
    }
`

export const ContendVideo = `
    type
    video
`

export const ContendPdf = `
    type
    pdf {
        permalink
    }
    link_text
`

export const Tag = `
    type
    text
    link
`

export const GraphicTable = `
    type
    table {
        text
        title
    }
`
